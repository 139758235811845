<template>
  <b-media
    v-if="product && $store.getters['app/windowSize'] <= 767"
  >
    <template
      #aside
    >
      <b-img
        :src="$helpers.imageHelper(product.product.picture)"
        :alt="product.product.item_name"
        rounded
        style="height: 50px; width: 50px;"
        @error="$helpers.imageError"
      />
    </template>
    <div
      v-if="product.product"
      class="media-heading"
    >
      <h6
        class="cart-item-title w-100"
        @click="that.toggleTimings(product.product)"
      >
        <b-link class="text-body show-all d-flex align-items-center">
          {{ product.product.item_name }}
          <template
            v-if="product.product.unit_value && product.product.unit_id && product.product.main_unit"
          > ({{ product.product.unit_value }} {{ product.product.main_unit.name }})</template>
          <feather-icon
            v-if="(product.cartaddon && product.cartaddon.length > 0) || that.getVariations(product.product_data).length > 0"
            class="ml-1"
            :icon="that.productInfo[product.product.id] ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            size="18"
          />
        </b-link>
      </h6>
    </div>
    <div
      v-if="that.productInfo[product.product.id]"
      class="read-more-carts"
    >
      <div
        v-if="product.cartaddon && product.cartaddon.length > 0"
        class="addons-wrapper"
      >
        <p class="mb-0">
          {{ $t('Addons') }}
        </p>
        <ul style="padding-left: 15px; list-style: decimal">
          <li
            v-for="(addon, index) of product.cartaddon"
            :key="index"
          >
            {{ addon.addon_name }} {{ $t('at') }} {{ product.store.currency_symbol }} {{ addon.addon_price }}
          </li>
        </ul>
      </div>
      <div
        v-if="that.getVariations(product.product_data).length > 0"
        class="details-wrapper"
      >
        <p class="mb-0">
          {{ $t('Other details') }}
        </p>
        <ul
          style="padding-left: 15px"
          class="mb-0"
        >
          <li
            v-for="(addon, index) of that.getVariations(product.product_data)"
            :key="index"
          >
            {{ addon[0] }}: {{ addon[1] }}
          </li>
        </ul>
      </div>
    </div>
    <div class="item-quantity mt-1 d-flex justify-content-between">
      <b-form-spinbutton
        v-model="product.quantity"
        size="sm"
        min="0"
        inline
        @change="that.updateQuantity(product)"
      />
      <div class="item-cost d-flex align-items-center ml-1">
        <small
          class="item-price"
          style="color: #000"
        >
          {{ product.store.currency_symbol }} {{ product.total_item_price }}
        </small>
      </div>
    </div>
  </b-media>
  <div
    v-else
    class="ecommerce-card no-hover"
  >
    <div class="item-img">
      <b-link>
        <b-img
          :src="$helpers.imageHelper(product.product ? product.product.picture : '')"
          :alt="product.product.item_name"
          @error="$helpers.imageError"
        />
      </b-link>
    </div>
    <b-card-body>
      <div class="item-name">
        <h6 class="mb-0">
          <b-link class="text-body show-all">
            {{ product.product.item_name }}
            <span
              v-if="product.product.unit_value && product.product.unit_id && product.product.main_unit"
              style="margin-left: 3px"
            >({{ product.product.unit_value }} {{ product.product.main_unit.name }})</span>
          </b-link>
        </h6>
        <div
          v-if="product.cartaddon && product.cartaddon.length > 0"
          class="addons-wrapper"
        >
          <p class="mb-0">
            {{ $t('Addons') }}
          </p>
          <ul style="padding-left: 15px; list-style: decimal">
            <li
              v-for="(addon, index) of product.cartaddon"
              :key="index"
            >
              {{ addon.addon_name }} {{ $t('at') }} {{ product.store.currency_symbol }} {{ addon.addon_price }}
            </li>
          </ul>
        </div>
        <div class="item-options text-center p-0">
          <div class="item-wrapper">
            <div class="item-cost">
              <small
                class="item-price d-flex"
                style="color: #000"
              >
                {{ product.store.currency_symbol }} {{ product.total_item_price }}
              </small>
            </div>
          </div>
        </div>
        <div
          v-if="that.getVariations(product.product_data).length > 0"
          class="details-wrapper"
        >
          <p class="mb-0">
            {{ $t('Other details') }}
          </p>
          <ul
            style="padding-left: 15px"
            class="mb-0"
          >
            <li
              v-for="(addon, index) of that.getVariations(product.product_data)"
              :key="index"
            >
              {{ addon[0] }}: {{ addon[1] }}
            </li>
          </ul>
        </div>
      </div>
      <div class="item-quantity mt-1">
        <span class="quantity-title">{{ $t('Qty') }}:</span>
        <b-form-spinbutton
          v-model="product.quantity"
          size="sm"
          min="0"
          class="ml-75"
          inline
          @change="that.updateQuantity(product)"
        />
      </div>
    </b-card-body>
  </div>
</template>

<script>
import { BCardBody, BLink, BImg, BFormSpinbutton, BMedia } from 'bootstrap-vue'

export default {
  components: {
    BCardBody,
    BLink,
    BImg,
    BFormSpinbutton,
    BMedia,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    that: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
