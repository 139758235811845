<template>
  <b-modal
    id="stripePaymentModal"
    v-model="showPaymentModal"
    :no-close-on-backdrop="true"
    :title="$t('Pay via Stripe')"
    hide-footer
    @hidden="paymentCancelled"
    size="xl"
  >
    <div class="make-stripe-payment">
      <form id="payment-form" @submit.prevent="capturePayment">
        <div id="payment-element"> </div>
        <div class="text-center mt-2 d-flex align-items-end" style="height: 140px">
          <button class="btn btn-danger mr-2" @click="clearForm" :disabled="cardConfigs.disabledButton">
            {{ $t('Cancel') }}
          </button>
          <button class="btn btn-primary" type="submit" id="submit" :disabled="cardConfigs.disabledButton">
            {{ $t('Pay Now') }}
          </button>
        </div>
        <div id="payment-message" class="hidden"></div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import eventBus from '@/libs/event-bus'
import { useUserUi } from '../useUser'

export default {
  props: {
    that: {
      type: Object,
      default: () => { },
    },
    adminService: {
      type: String,
      default: 'ORDER',
    },
  },
  data() {
    return {
      showPaymentModal: false,
      orderId: '',
      cardConfigs: {
        disabledButton: false,
        errorText: '',
      },
      stripePublicKey: null,
      stripeInfo: {
        card: null,
        stripe: null,
        elements: null,
        data: null,
      },
    }
  },
  methods: {
    resetCard() {
      this.cardConfigs = {
        disabledButton: false,
        errorText: '',
      }
    },
    paymentCancelled($event) {
      this.$emit('form-cancelled', $event)
    },
    clearForm() {
      this.showPaymentModal = false
      this.$emit('form-cancelled')
    },
    async initStripeKey(stripeKey) {
      this.showPaymentModal = true
      showLoader()
      const params = {
        order_id: this.orderId,
        admin_service: this.adminService,
      }
      if (this.adminService === 'SERVICE') {
        params.request_id = this.orderId
      }
      const { makeStripePayment } = useUserUi()
      const responseData = await makeStripePayment(params)
      if(responseData?.data?.responseData?.client_secret){
        this.stripeInfo.stripe = window.Stripe(stripeKey)
        const clientSecret = responseData?.data?.responseData?.client_secret
        this.stripeInfo.data = responseData?.data?.responseData;
        const appearance = {
          theme: 'stripe'
        };
        this.stripeInfo.elements = this.stripeInfo.stripe.elements({ clientSecret, appearance });
        const paymentElementOptions = {
          layout: "accordion",
        };
      this.cardConfigs.disabledButton = false
      this.cardConfigs.disabledButton = false

        this.cardConfigs.disabledButton = false

        const paymentElement = this.stripeInfo.elements.create("payment", paymentElementOptions);
        paymentElement.mount("#payment-element");
      }
      hideLoader()
    },
    checkStatus() {
      const order_id = new URLSearchParams(window.location.search).get(
        "orderId"
      );
      const { retryPayment } = useUserUi()
      const formData = new FormData
      formData.append('payment_method', 'stripe')
      formData.append('admin_service', this.adminService)
      formData.append('order_id', order_id)
      formData.append('stripeToken', order_id)
      retryPayment(formData)
        .then(async ({ data }) => {
          const stripeKey = data?.responseData?.stripe_publishable_key
          const stripe = window.Stripe(stripeKey)
          const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
          );

          if (!clientSecret) {
            return;
          }
          const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
          switch (paymentIntent.status) {
            case "succeeded":
              const params = {
                order_id: this.orderId,
                admin_service: this.adminService,
                transaction_id: paymentIntent.id,
              }
              const { completeStripePayment } = useUserUi()
              await completeStripePayment(params).then(r => {
                this.$emit('order-placed-successfully')
                eventBus.$emit('refresh-on-going-services')
                this.$emit('payment-done')
                window.SuperApp.actions.newOrder()
                showSuccessNotification(this, this.$t('Order placed successfully'))
              })
              break;
            case "processing":
              showErrorNotification(this, "Your payment is processing.");
              break;
            case "requires_payment_method":
              showErrorNotification(this, "Your payment was not successful, please try again.");
              break;
            default:
              showErrorNotification(this, "Something went wrong.");
              break;
          }
        })
    },
    async capturePayment(e) {
      e.preventDefault();
      window.stripePaymentModule.cardConfigs.disabledButton = true
      showLoader()
      const { error } = await this.stripeInfo.stripe.confirmPayment({
        elements: this.stripeInfo.elements,
        confirmParams: {
          return_url: `${window.location.href}`,
        },
      });
      if (error.type === "card_error" || error.type === "validation_error") {
        showErrorNotification(this, error.message);
      } else {
        showErrorNotification(this, "An unexpected error occurred.");
      }
      this.clearForm()
      hideLoader()
    },
  },
}
</script>
