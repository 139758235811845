<template>
  <div class="list-view product-checkout mt-0 user-ecommerce-checkout-payment">
    <div class="checkout-payment">
      <b-card no-body>
        <b-card-header class="flex-column align-items-start">
          <b-card-title>{{ $t('Payment Options') }}</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-form-group :label="$t('Promo')">
            <b-form-group class="wrapper-payment">
              <b-img
                class="common-width-images"
                :src="require('@/assets/images/payment/Promo.png')"
              />
              <b-form-radio
                v-model="paymentInfo.applyPromocode"
                name="promocode-type-radio"
                :value="'YES'"
                @change="promocodeChanged"
              >
                {{ $t('Promo Code') }}
              </b-form-radio>
            </b-form-group>
            <b-badge
              v-if="paymentInfo.isPromocodeApplied"
              pill
              variant="light-primary"
              class="ml-1"
              @click="removePromocode"
            >
              {{ $t('Remove Promocode') }}
            </b-badge>
          </b-form-group>
          <!-- <b-form-group :label="$t('Order Type')">
            <b-form-group
              v-for="(item, index) of orderType.filter(x => x.value)"
              :key="index"
              class="wrapper-payment"
            >
              <b-img
                :title="item.text"
                class="common-width-images"
                :src="item.icon"
              />
              <b-form-radio
                v-model="paymentInfo.orderType"
                name="order-type-radio"
                :value="item.value"
                @change="setOrderType(item.value)"
              >
                {{ $t(item.text) }}
              </b-form-radio>
            </b-form-group>
            <b-form-select
              v-model="paymentInfo.orderType"
              class="d-none"
              :options="orderType"
              @change="getPromoCodeData"
            />
          </b-form-group> -->
          <b-form-group>
            <label class="col-form-label pt-0 pb-0">{{ $t('Payment Option') }}</label>
            <p class="mb-1 small">
              {{ $t('Be sure to click on correct payment option') }}
            </p>
            <!-- <div
              v-if="$superApp.walletEnabled"
              class="d-flex align-items-center wrapper-payment"
            >
              <b-img
                class="common-width-images"
                :title="'Wallet'"
                :src="require('@/assets/images/app-icons/wallet.png')"
                :class="{ active: paymentInfo.paymentMode === 'WALLET' }"
              />
              <b-form-radio
                v-model="paymentInfo.paymentMode"
                name="payment-mode-radio"
                :value="'WALLET'"
              >
                {{ $t('Wallet') }}: {{ entireCart.user_wallet_balance }} {{ entireCart.user_currency }}
              </b-form-radio>
            </div> -->
            <div
              v-if="entireCart.cash == 1"
              class="d-flex align-items-center wrapper-payment"
            >
              <b-img
                class="common-width-images"
                :title="'Cash'"
                :src="require('@/assets/images/payment/Cash.png')"
                :class="{ active: paymentInfo.paymentMode === 'CASH' }"
              />
              <b-form-radio
                v-model="paymentInfo.paymentMode"
                name="payment-mode-radio"
                :value="'CASH'"
              >
                {{ $t('Cash') }}
              </b-form-radio>
            </div>
            <div
              v-if="entireCart.card == 1"
              class="d-flex align-items-center wrapper-payment"
            >
              <b-img
                class="common-width-images"
                :title="'Card'"
                :src="require('@/assets/images/payment/Card payment.png')"
                :class="{ active: paymentInfo.paymentMode === 'CARD' }"
              />
              <b-form-radio
                v-model="paymentInfo.paymentMode"
                name="payment-mode-radio"
                :value="'CARD'"
              >
                {{ $t('Card') }}
              </b-form-radio>
            </div>
            <div
              v-if="entireCart.card_on_delivery == 1"
              class="d-flex align-items-center wrapper-payment"
            >
              <b-img
                class="common-width-images"
                :title="'Card On Delivery'"
                :src="require('@/assets/images/app-icons/CARD_ON_DELIVERY.png')"
                :class="{ active: paymentInfo.paymentMode === 'on_delivery' }"
              />
              <b-form-radio
                v-model="paymentInfo.paymentMode"
                name="payment-mode-radio"
                :value="'on_delivery'"
              >
                {{ $t('Card On Delivery') }}
              </b-form-radio>
            </div>
            <b-form-select
              v-model="paymentInfo.paymentMode"
              class="d-none"
              :options="paymentOptions"
            />
          </b-form-group>
          <b-form-group
            v-if="paymentInfo.paymentMode === 'CARD'"
            :label="$t('Payment Type')"
            class="wrapper-payment-info-type"
          >
            <b-form-group
              v-for="(item, index) of paymentTypeList.filter(x => x.value)"
              :key="index"
              class="wrapper-payment"
            >
              <b-form-radio
                v-model="paymentInfo.paymentType"
                name="payment-type-radio"
                :value="item.value"
              />
              <b-img
                :title="item.text"
                class="common-width-images"
                :src="item.icon"
                :class="{ 'w-80px': item.class === 'w-80px', active: paymentInfo.paymentType === item.value, 'w-75px': item.value === 'paypal' }"
                @click="paymentInfo.paymentType = item.value"
              />
            </b-form-group>
            <b-form-select
              v-model="paymentInfo.paymentType"
              class="d-none"
              :options="paymentTypeList"
            />
          </b-form-group>
          <b-form-group
            v-if="paymentInfo.paymentMode === 'CARD' && showCardOption"
            :label="$t('Choose Card')"
          >
            <b-form-select
              v-model="paymentInfo.cardId"
              :options="[
                { text: 'Select Card', value: '' },
                ...userCards.map(x => ({
                  text: `Card ends with XXXX-XXXX-XXXX-${x.last_four} for ${x.holder_name}`,
                  value: x.id,
                })),
              ]"
            />
          </b-form-group>
        </b-card-body>
      </b-card>
      <b-modal
        v-model="isPromocodeVisible"
        :title="$t('Promocode')"
        :no-close-on-backdrop="true"
        @hidden="cancelPromocode"
      >
        <div class="row">
          <div class="col-md-12">
            <b-form-group :label="$t('Enter Coupon Code')">
              <div class="row">
                <div class="col-8">
                  <b-form-input
                    v-model="paymentInfo.couponCode.promo_code"
                    type="text"
                    disabled
                  />
                </div>
                <div class="col-3">
                  <b-button @click="okPromocode" variant="primary" :disabled="paymentInfo.couponCode.promo_code == null || paymentInfo.couponCode.promo_code == ''">
                    {{ $t('Apply') }}
                  </b-button>
                </div>
              </div>
            </b-form-group>
          </div>
          <div
            v-for="(coupon, index) of promocodeList"
            :key="index"
            class="col-md-12"
          >
            <b-card
              class="cursor-pointer"
              @click="applyPromocode(coupon)"
            >
              <b-card-header class="flex-column align-items-start px-0">
                <b-card-title style="word-break: break-all">
                  {{ coupon.promo_description }}
                </b-card-title>
              </b-card-header>
              <b-card-body class="p-0">
                <div class="row">
                  <div class="col-7">
                    <span
                      class="badge badge-primary badge-pill mr-1 mb-1"
                      style="padding: 10px 20px; font-size: 20px"
                    >{{ coupon.promo_code }}</span>
                  </div>
                  <div class="col-5">
                    <img
                      :src="$helpers.imageHelper(coupon.picture)"
                      class="w-100"
                      @error="$helpers.imageError"
                    >
                  </div>
                </div>
                <!-- <p class="mb-0 small">
                  {{ $t('Valid Till') }}: {{ coupon.expiration }}
                </p> -->
              </b-card-body>
            </b-card>
          </div>
        </div>
      </b-modal>
      <b-modal
        v-model="showDeliveryOptions"
        :title="$t('Delivery Options')"
        :no-close-on-backdrop="true"
        ok-only
        scrollable
        no-close-on-esc
        hide-header-close 
        :ok-title="$t('Apply')"
        :ok-disabled="selectedDelivery == ''"
        @ok="submitDeliveryOption"
      >
        <b-form-group
          label="Sort By:"
          label-for="sort-by-del-opt"
        >
          <b-form-select v-model="deliverySort" :options="deliverySortOpts"></b-form-select>
        </b-form-group>
        <b-form-group :label="''">
          <b-form-radio-group v-model="selectedDelivery">
            <div
              v-for="(item, index) of deliveryOptsFiltered"
              :key="index"
              class="card mb-1 delivery-options-picker"
            >
              <div class="card-body p-1">
                <b-form-radio
                  class="custom-radio-right align-items-center d-flex w-100 mb-0 cursor-pointer"
                  :value="item.id"
                >
                  <div class="wrapper-content-radio">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <div class="mb-1"><h4>{{ item.courier_name }}</h4></div>
                        <div class="d-flex justify-content-between">
                          <div class="mr-1">
                            <b-img
                              v-if="item.is_surface"
                              :src="require('@/assets/images/app-icons/express-delivery-2.png')"
                              width="25"
                            />
                            <b-img
                              v-else
                              :src="`${$apiUrl}/images/icons/share.svg`"
                              width="25"
                            />
                          </div>
                          <div class="rating-bar d-flex">
                            <ul class="unstyled-list list-inline mb-0 mr-1">
                              <li
                                v-for="star in 5"
                                :key="star"
                                class="ratings-list-item"
                                :class="{ 'ml-25': star - 1 }"
                              >
                                <feather-icon
                                  icon="StarIcon"
                                  size="16"
                                  :class="[{ 'fill-current': star <= item.rating }, star <= item.rating ? 'text-warning' : 'text-muted']"
                                />
                              </li>
                            </ul>
                            {{ item.rating }} / 5
                          </div>
                        </div>
                      </div>
                      <div>
                        <h3>{{ storeCurrency }}{{ item.rate }}</h3>
                      </div>
                    </div>
                  </div>
                </b-form-radio>
              </div>
            </div>
          </b-form-radio-group>
        </b-form-group>
      </b-modal>
    </div>
    <user-e-commerce-cart-info
      :button-text-next="'Place Order'"
      :selected-delivery-option="selectedDeliveryOption"
      :is-payment-page="paymentInfo.paymentMode === 'CARD' && paymentInfo.orderType === 'DELIVERY'"
      :storeData="storeData"
      @contact-less-changed="contactLessChanged"
      @next-step="nextStep"
    />
  </div>
</template>

<script>
import { BCard, BFormRadioGroup, BFormRadio, BCardHeader, BCardTitle, BCardBody, BImg, BModal, BFormGroup, BFormInput, BBadge, BFormSelect, BButton } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import UserECommerceCartInfo from './UserECommerceCartInfo.vue'
import { useUserUi } from '../../useUser'

export default {
  components: {
    BCard,
    BFormRadio,
    BCardHeader,
    BFormRadioGroup,
    BCardTitle,
    UserECommerceCartInfo,
    BCardBody,
    BImg,
    BModal,
    BFormGroup,
    BFormInput,
    BBadge,
    BButton,
    BFormSelect,
  },
  props: {
    selectedAddress: {
      type: Number,
      default: 0,
    },
    storeData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      showDeliveryOptions: false,
      selectedDeliveryOption: null,
      deliveryOptions: [],
      selectedDelivery: '',
      showCardOption: false,
      promocodeList: [],
      paymentTypeList: [],
      isPromocodeVisible: false,
      paymentInfo: {
        couponCode: {},
        paymentMode: 'CASH',
        useWalletOption: false,
        isPromocodeApplied: false,
        orderType: 'DELIVERY',
        paymentType: '',
        contactless_delivery: false,
        cardId: '',
        applyPromocode: false,
      },
      userCards: [],
      paymentOptions: [
        {
          text: 'Cash',
          value: 'CASH',
        },
        {
          text: 'Card',
          value: 'CARD',
        },
        {
          text: 'On Delivery',
          value: 'on_delivery',
        },
      ],
      orderType: [
        {
          text: 'Delivery',
          value: 'DELIVERY',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/payment/Delivery.png'),
        },
        {
          text: 'Pickup',
          value: 'TAKEAWAY',
          // eslint-disable-next-line global-require
          icon: require('@/assets/images/payment/take-away.png'),
        },
      ],
      deliverySort: 'pricing-lh',
      deliverySortOpts: [
        { value: 'pricing-lh', text: 'Pricing Low to High' },
        { value: 'pricing-hl', text: 'Pricing High to Low' },
        { value: 'rating-lh', text: 'Rating Low to High' },
        { value: 'rating-hl', text: 'Rating High to Low' },
      ]
    }
  },
  computed: {
    entireCart() {
      return this.$store.state['app-ecommerce'].entireCart
    },
    storeCurrency() {
      if (this.entireCart && this.entireCart.carts && this.entireCart.carts[0] && this.entireCart.carts[0].store) {
        return this.entireCart.carts[0].store.currency_symbol
      }
      return ''
    },
    deliveryOptsFiltered: {
      get(){
        const opts = this.deliveryOptions
        switch (this.deliverySort) {
          case 'pricing-lh':
            return opts.sort((a,b) => a.rate > b.rate)
            break;
          case 'pricing-hl':
            return opts.sort((a,b) => a.rate < b.rate)
            break;
          case 'rating-lh':
            return opts.sort((a,b) => a.rating > b.rating)
            break;
          case 'rating-hl':
            return opts.sort((a,b) => a.rating < b.rating)
            break;
        
          default:
            break;
        }
      }
    }
  },
  watch: {
    selectedAddress: {
      immediate: true,
      handler() {
        this.getPromoCodeData()
      },
    },
    entireCart: {
      immediate: true,
      handler() {
        if (this.entireCart) {
          if (this.entireCart.cash) {
            this.paymentInfo.paymentMode = 'CASH'
          } else if (this.entireCart.card) {
            this.paymentInfo.paymentMode = 'CARD'
          } else {
            this.paymentInfo.paymentMode = 'on_delivery'
          }
        }
      },
    },
  },
  mounted() {
    const { userPromocode } = useUserUi()
    this.$store.commit('app-ecommerce/UPDATE_USER_PROMOCODES', [])
    userPromocode(this.entireCart ? `?store_id=${this.entireCart.store_id}` : '')
      .then(({ data }) => {
        if (data.statusCode === '200') {
          this.promocodeList = data.responseData
          this.$store.commit('app-ecommerce/UPDATE_USER_PROMOCODES', this.promocodeList)
          if (this.entireCart && this.entireCart.promocode_id && this.promocodeList) {
            this.paymentInfo.couponCode = this.promocodeList.find(x => x.id === this.entireCart.promocode_id)
            if (this.paymentInfo.couponCode) {
              this.paymentInfo.isPromocodeApplied = true
              this.isPromocodeVisible = false
            }
          }
        }
      })
      .catch(error => {
        showErrorNotification(this, error)
      })
    this.getUserCardList()
  },
  methods: {
    cancelDeliveryOption() {
      this.selectedDeliveryOption = null
      this.selectedDelivery = ''
      this.$emit('set-delivery-option', this.selectedDeliveryOption)
    },
    submitDeliveryOption() {
      // eslint-disable-next-line eqeqeq
      this.selectedDeliveryOption = this.deliveryOptions.find(x => x.id == this.selectedDelivery)
      this.$emit('set-delivery-option', this.selectedDeliveryOption)
      if (this.selectedDeliveryOption) {
        this.getPromoCodeData('CART')
      }
    },
    contactLessChanged(isContactLess) {
      this.paymentInfo.contactless_delivery = isContactLess
    },
    promocodeChanged() {
      if (this.paymentInfo.applyPromocode) {
        this.showPromocode()
      }
    },
    getUserCardList() {
      const { userCardList } = useUserUi()
      userCardList()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.userCards = data.responseData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    nextStep() {
      if (this.paymentInfo.paymentMode !== 'CARD') {
        this.paymentInfo.contactless_delivery = 0
      }
      this.paymentInfo.useWalletOption = this.paymentInfo.paymentMode === 'WALLET'
      this.$emit('next-step', this.paymentInfo)
    },
    cancelPromocode() {
      this.paymentInfo.applyPromocode = false
      this.isPromocodeVisible = false
    },
    okPromocode() {
      this.paymentInfo.isPromocodeApplied = true
      this.isPromocodeVisible = false
      this.paymentInfo.applyPromocode = true
      this.getPromoCodeData()
    },
    showPromocode() {
      this.isPromocodeVisible = true
    },
    applyPromocode(coupon) {
      this.paymentInfo.couponCode = coupon
      console.log(this.paymentInfo.couponCode)
    },
    removePromocode() {
      this.paymentInfo.applyPromocode = false
      this.paymentInfo.couponCode = {}
      this.paymentInfo.isPromocodeApplied = false
      this.getPromoCodeData()
    },
    setOrderType(value) {
      this.paymentInfo.orderType = value
      this.getPromoCodeData()
    },
    getPromoCodeData(apiType = '') {
      if(this.paymentInfo.orderType === 'DELIVERY'){
        this.$emit('need-delivery-option', false)
      }
      showLoader()
      const payloads = []
      const formData = {}
      if (this.paymentInfo.couponCode && this.paymentInfo.couponCode.id && this.paymentInfo.isPromocodeApplied) {
        payloads.push(`promocode_id=${this.paymentInfo.couponCode.id}`)
        payloads.push('wallet=0')
        formData.promocode_id = this.paymentInfo.couponCode.id
        formData.wallet = '0'
      }
      if (this.selectedAddress) {
        payloads.push(`user_address_id=${this.selectedAddress}`)
        formData.user_address_id = this.selectedAddress
      }
      payloads.push(`order_type=${this.paymentInfo.orderType}`)
      formData.order_type = this.paymentInfo.orderType
      if (this.selectedAddress && this.selectedDeliveryOption) {
        payloads.push(`courier=${JSON.stringify(this.selectedDeliveryOption)}`)
      }
      const callVerify = apiType === 'CART' ? false : this.selectedAddress
      this.$store
        .dispatch(callVerify ? 'app-ecommerce/verifyCheckoutAddress' : 'app-ecommerce/fetchCouponsCartProducts', callVerify ? formData : `?${payloads.join('&')}`)
        .then(response => {
          if (response.data.statusCode === '200') {
            const items = response.data.responseData.carts
            this.paymentInfo.paymentType = ''
            if (this.paymentInfo.isPromocodeApplied) {
              this.paymentInfo.applyPromocode = 'YES'
            } else {
              this.paymentInfo.applyPromocode = 'NO'
            }
            this.paymentTypeList = [
              {
                text: 'Select Payment Type',
                value: '',
              },
            ]
            const cardData = response.data.responseData
            // eslint-disable-next-line no-param-reassign
            response.data.responseData.no_options_available = false
            if (cardData.courier_pricing) {
              if (cardData.delivery_options && Array.isArray(cardData.delivery_options)) {
                if(this.paymentInfo.orderType === 'DELIVERY'){
                  this.$emit('need-delivery-option', true)
                }
                this.selectedDeliveryOption = null
                this.deliveryOptions = cardData.delivery_options
                let courierId = null
                if (this.entireCart.carts && this.entireCart.carts[0] && this.entireCart.carts[0].courier) {
                  courierId = this.entireCart.carts[0].courier.id
                }
                const isAlreadySelected = this.deliveryOptions.find(x => x.id === courierId)
                if (isAlreadySelected) {
                  this.selectedDelivery = isAlreadySelected.id
                }
                this.showDeliveryOptions = true
              } else if (this.selectedAddress && this.paymentInfo.orderType === 'DELIVERY' && apiType !== 'CART') {
                showDangerNotification(this, 'No delivery options available')
                // eslint-disable-next-line no-param-reassign
                response.data.responseData.no_options_available = true
              }
            }
            if (cardData.stripe) {
              this.paymentTypeList.push({
                text: 'Stripe',
                value: 'stripe',
                // eslint-disable-next-line global-require
                icon: `${this.$apiUrl}/images/icons/stripe.svg`,
              })
            }
            if (cardData.paypal) {
              this.paymentTypeList.push({
                text: 'Paypal',
                value: 'paypal',
                // eslint-disable-next-line global-require
                icon: `${this.$apiUrl}/images/icons/paypal.svg`,
              })
            }
            if (cardData.phonepe) {
              this.paymentTypeList.push({
                text: 'PhonePe',
                value: 'phonepe',
                // eslint-disable-next-line global-require
                icon: `${this.$apiUrl}/images/icons/phonepe.svg`,
                class: 'w-80px',
              })
            }
            if (cardData.razor) {
              this.paymentTypeList.push({
                text: 'Razor Pay',
                value: 'razor',
                // eslint-disable-next-line global-require
                icon: `${this.$apiUrl}/images/icons/razorpay.svg`,
                class: 'w-80px',
              })
            }
            if (cardData.flutterwave) {
              this.paymentTypeList.push({
                text: 'Flutterwave',
                value: 'flutterwave',
                icon: require('@/assets/images/app-icons/sub-menu/Logo_FullColor.png'),
                class: 'w-80px',
              })
            }
            this.$store.commit('app-ecommerce/UPDATE_ENTIRE_CART_ITEMS', response.data.responseData)
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', items.length)
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS', items)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
          this.$emit('reset-delivery')
        })
    },
  },
}
</script>

<style lang="scss">
.delivery-options-picker {
  .custom-control-label {
    width: 100%;
  }

  .cursor-pointer {
    cursor: pointer;
  }
}
</style>

<style scoped>
.common-width-images {
  width: 40px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px;
  border: 1px solid #fff;
}

.user-ecommerce-checkout-payment .custom-radio .custom-control-label::before {
  top: 50%;
  transform: translateY(-50%);
}

.w-80px {
  width: 100px;
}

.w-75px {
  width: 75px;
}
</style>

<style>
.wrapper-payment > div {
  display: flex;
  align-items: center;
}

.wrapper-payment-info-type > div {
  display: flex;
  align-items: center;
  margin-left: 60px;
}

@media only screen and (max-width: 767px) {
  .wrapper-payment-info-type > div {
    display: block;
  }

  .wrapper-payment-info-type .wrapper-payment > div {
    height: 30px;
  }
}
</style>
