<template>
  <div class="checkout-items user-ecommerce-checkout-cart-product">
    <template v-if="$store.getters['app/windowSize'] <= 767">
      <div
        v-for="product in products"
        :key="product.id"
        class="d-flex mb-1 w-100 box-shadow-card"
        style="padding: 7px 10px 7px 5px;"
      >
        <checkout-products
          :product="product"
          class="w-100"
          :that="that"
        />
      </div>
    </template>
    <template v-else>
      <b-card
        v-for="product in products"
        :key="product.id"
        no-body
      >
        <checkout-products
          :product="product"
          :that="that"
        />
      </b-card>
    </template>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import CheckoutProducts from './CheckoutProducts.vue'

export default {
  components: {
    BCard,
    CheckoutProducts,
  },
  data() {
    return {
      productInfo: {},
    }
  },
  computed: {
    products() {
      return this.$store.state['app-ecommerce'].cartItems
    },
    entireCart() {
      return this.$store.state['app-ecommerce'].entireCart
    },
    that() {
      return this
    },
    categories() {
      if (Array.isArray(this.products)) {
        const categories = this.products.filter(x => x.product && x.product.categories).map(x => x.product.categories)
        const grouped = window.SuperApp.actions.groupArray(categories, x => x.id)
        return grouped.map(x => x[0])
      }
      return []
    },
  },
  methods: {
    getVariations(products) {
      if (!products) return []
      try {
        return Object.entries(JSON.parse(products))
      } catch (error) {
        return []
      }
    },
    toggleTimings(product) {
      this.productInfo = {
        ...this.productInfo,
        [product.id]: !this.productInfo[product.id],
      }
    },
    removeProductFromCartClick(item) {
      showLoader()
      const formData = new FormData()
      formData.append('cart_id', item.id)
      this.$store
        .dispatch('app-ecommerce/removeFromCart', formData)
        .then(response => {
          if (response.data.statusCode === '200') {
            const items = response.data.responseData.carts
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', items.length)
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS', items)
            this.$store.commit('app-ecommerce/UPDATE_ENTIRE_CART_ITEMS', response.data.responseData)
            showSuccessNotification(this, 'Item removed successfully')
            if (items.length === 0) {
              this.$router.push({
                path: `/user/store/details/${this.$route.params.storeName}`,
              })
            }
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    updateQuantity(item, addOns = '') {
      if (item.quantity === 0) {
        this.removeProductFromCartClick(item)
        return
      }
      showLoader()
      this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS', this.items)
      const formData = new FormData()
      formData.append('item_id', item.provider_service ? item.provider_service.id : item.product.id)
      formData.append('cart_id', item.id)
      formData.append('qty', item.quantity)
      formData.append('addons', addOns || '')
      formData.append('repeat', 1)
      formData.append('customize', 0)
      let moduleLink = 'app-ecommerce/updateQuantity'
      if (this.isServicePage) moduleLink = 'app-ecommerce/updateServiceQuantity'
      this.$store
        .dispatch(moduleLink, formData)
        .then(response => {
          if (response.data.statusCode === '200') {
            this.items = response.data.responseData.carts
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', this.items.length)
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS', this.items)
            this.$store.commit('app-ecommerce/UPDATE_ENTIRE_CART_ITEMS', response.data.responseData)
            showSuccessNotification(this, 'Quantity updated successfully')
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
.checkout-items.user-ecommerce-checkout-cart-product {
  .ecommerce-card {
    .item-img {
      img {
        width: 300px;
        height: 250px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .checkout-items.user-ecommerce-checkout-cart-product {
    .card {
      margin-bottom: 0 !important;
    }
    .ecommerce-card {
      margin-bottom: 0;
      grid-template-columns: 1fr 2fr !important;
      align-items: flex-start;
      .item-img {
        img {
          width: 130px !important;
          height: auto;
        }
      }

      .remove-wishlist,
      .quantity-title {
        display: none !important;
      }

      .item-price {
        white-space: nowrap;
      }
    }
  }
}
</style>
