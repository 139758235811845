<template>
  <div class="list-view product-checkout mt-0 user-ecommerce-cart-address">
    <div class="checkout-address">
      <div class="row">
        <b-col
          md="6"
          class="cursor-pointer customer-card user-address-cards"
          @click="addNewAddress"
        >
          <b-card
            no-body
            style="height: 90%;"
            class="card-address-info"
          >
            <b-card-body style="display: grid;place-content: center;">
              <p class="font-20 text-black">
                {{ $t('Add new address') }} <feather-icon icon="PlusIcon" />
              </p>
            </b-card-body>
          </b-card>
        </b-col>
        <user-address-cards
          v-for="address of addressInfo"
          :key="address.id"
          :selected-address="selectedAddress"
          :address-obj="address"
          class="col-md-6"
          @deliver-here="deliverHere"
          @edit-address="editAddressInfo"
          @delete-address="deleteAddress"
        />
        <b-modal
          v-model="toggleEditModal"
          :no-close-on-backdrop="true"
          :title="$t('Address Details')"
          :ok-title="$t('Save')"
          hide-footer
          size="lg"
          @ok="submitAddress"
        >
          <address-modal
            ref="addressModal"
            @address-selected="setPlace"
            @set-address="setAdress"
            @save-details="() => {
              toggleEditModal = false
              submitAddress()
            }"
          />
        </b-modal>
      </div>
    </div>
    <user-e-commerce-cart-info
      :button-text-next="'Continue'"
      :selected-delivery-option="selectedDeliveryOption"
      @next-step="nextStep"
    />
  </div>
</template>

<script>
import { BCol, BCard, BCardBody, BModal } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import UserAddressCards from '../../shared/UserAddressCards.vue'
import { useUserUi } from '../../useUser'
import UserECommerceCartInfo from './UserECommerceCartInfo.vue'
import AddressModal from '../../profile/AddressModal.vue'

export default {
  components: {
    BCol,
    BCard,
    BModal,
    BCardBody,
    AddressModal,
    UserECommerceCartInfo,
    UserAddressCards,
  },
  props: {
    selectedDeliveryOption: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      toggleEditModal: false,
      addressInfo: [],
      editAddress: {},
      selectedAddress: null,
      markers: null,
      center: { lat: 45.508, lng: -73.587 },
    }
  },
  mounted() {
    this.getUserAddress()
  },
  methods: {
    getUserAddress() {
      const { userAddressInfo } = useUserUi()

      userAddressInfo()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.addressInfo = data.responseData.reverse()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    setPlace(place) {
      if (place) {
        const marker = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }
        this.editAddress.map_address = place.formatted_address
        this.editAddress.latitude = marker.lat
        this.editAddress.longitude = marker.lng
        this.markers = {
          position: marker,
        }
        this.center = marker
      }
    },
    setAdress(place) {
      this.editAddress = JSON.parse(JSON.stringify(place))
    },
    addNewAddress() {
      this.editAddress = {
        map_address: '',
        flat_no: '',
        street: '',
        address_type: '',
        id: 0,
        building: '',
        zipcode: '',
      }
      this.toggleEditModal = true
    },
    editAddressInfo(address) {
      this.editAddress = {
        ...address,
      }
      this.toggleEditModal = true
      this.$nextTick(() => {
        this.$refs.addressModal.editAddress = {
          ...address,
        }
      })
    },
    deleteAddress(addressObj) {
      this.$swal({
        title: this.$t('Please confirm you want to delete this address?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          showLoader()
          const { userDeleteAddress } = useUserUi()
          userDeleteAddress(addressObj.id)
            .then(({ data }) => {
              if (data.statusCode === '200') {
                this.getUserAddress()
                showSuccessNotification(this, data.message)
                hideLoader()
              }
            })
            .catch(error => {
              showErrorNotification(this, error)
            })
        }
      })
    },
    submitAddress() {
      this.setNewAddress(this.editAddress)
    },
    setNewAddress(address) {
      const { userAddNewAddress, updateUserAddress } = useUserUi()
      const formData = new FormData()
      formData.append('id', address.id)
      formData.append('map_address', address.map_address)
      formData.append('latitude', address.latitude)
      formData.append('longitude', address.longitude)
      formData.append('flat_no', address.flat_no)
      formData.append('street', address.street)
      formData.append('address_type', address.address_type)
      formData.append('building', address.building)
      formData.append('zipcode', address.zipcode)
      showLoader()
      const callType = address.id ? updateUserAddress : userAddNewAddress
      if (address.id) {
        formData.append('_method', 'PATCH')
      }
      callType(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.getUserAddress()
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    nextStep() {
      if (!this.selectedAddress) {
        showDangerNotification(this, window.location.href.includes('provider') ? 'Please select service location' : 'Please select delivery address')
      } else {
        this.$emit('next-step')
      }
    },
    deliverHere(address) {
      this.selectedAddress = address.id
      this.$emit('delivery-address', this.selectedAddress)
    },
  },
}
</script>
