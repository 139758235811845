<template>
  <div class="payment-retry-component">
    <div class="image-wrapper">
      <img
      class="payment_in_progress_img"
        :src="require('@/assets/images/payment/e-wallet-animate.svg')"
      >
      <p
        v-if="!$route.query.retry"
        class="text-danger mt-2 font-16"
      >
        {{ $t('Payment failed, please try again') }}
      </p>
      <p
        v-if="$route.query.retry"
        class="mt-2 font-16"
      >
        {{ $t('Please choose any 1 from below to continue') }}
      </p>
    </div>
    <b-form-group class="text-left">
      <label class="col-form-label pt-0 pb-0">{{ $t('Payment Option') }}</label>
      <p class="mb-1 small">
        {{ $t('Be sure to click on correct payment option') }}
      </p>
      <div
        v-if="paymentOptions.cash"
        class="d-flex align-items-center wrapper-payment"
      >
        <b-img
          class="common-width-images"
          :title="'Cash'"
          :src="require('@/assets/images/payment/Cash.png')"
          :class="{ active: paymentMode === 'CASH' }"
        />
        <b-form-radio
          v-model="paymentMode"
          name="payment-mode-radio"
          :value="'CASH'"
        >
          {{ $t('Cash') }}
        </b-form-radio>
      </div>
      <div
        v-if="paymentOptions.card_on_delivery"
        class="d-flex align-items-center wrapper-payment"
      >
        <b-img
          class="common-width-images"
          :title="'Card On Delivery'"
          :src="require('@/assets/images/app-icons/CARD_ON_DELIVERY.png')"
          :class="{ active: paymentMode === 'on_delivery' }"
        />
        <b-form-radio
          v-model="paymentMode"
          name="payment-mode-radio"
          :value="'on_delivery'"
        >
          {{ $t('Card On Delivery') }}
        </b-form-radio>
      </div>
      <div
        v-if="paymentOptions.card"
        class="d-flex align-items-center wrapper-payment"
      >
        <b-img
          class="common-width-images"
          :title="'Card'"
          :src="require('@/assets/images/payment/Card payment.png')"
          :class="{ active: paymentMode === 'CARD' }"
        />
        <b-form-radio
          v-model="paymentMode"
          name="payment-mode-radio"
          :value="'CARD'"
        >
          {{ $t('Card') }}
        </b-form-radio>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BImg, BFormRadio } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BImg,
    BFormRadio,
  },
  props: {
    paymentOptions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      paymentMode: 'CARD',
    }
  },
  watch: {
    paymentMode: {
      immediate: true,
      handler() {
        this.$emit('payment-type-selected', this.paymentMode)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.common-width-images {
  width: 40px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px;
  border: 1px solid #fff;
}
.payment_in_progress_img{
  height: 30vw;
}
@media only screen and (max-width: 767px) {
  .payment_in_progress_img{
    height: 60vw;
  }
}
</style>
